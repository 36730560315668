* {
    margin: 0;
    padding: 0;
}

#main {
    .intro {
        height: 100vh;
        display: flex;
        background-color: white;
        align-items: center;
        box-sizing: border-box;
        text-align: center;
        opacity: 0;
        overflow-y: hidden; // hide vertical
        overflow-x: hidden; 
        animation: intro 2s forwards;

        @keyframes intro {
            0% {} 

            50% {
                opacity: 0;
            } 
            
            100% {
                opacity: 1;
            } 
        }

        .wrapper {
            text-align: center;
            width: 100%;

            h1 {
                font-size: 46px;
            }

            h3 {
                opacity: 0.5;
            }
        }
    }

    .tiles {
        display: grid;
        grid-gap: 15px;
        padding: 15px;

        @media screen and (min-width: 1000px){
            grid-template-columns: repeat(3, 1fr);
        }

        .container {
            position: relative;
            border-radius: 10px;
            height: 100vh;
            padding: 20px;
            box-sizing: border-box;

            a {
                transition: 0.3s;

                img {
                    height: 150px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        .figure {
            height: 150px;
            background: #F9F4E1;
            width: 150px;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            animation: fig1 10s infinite;

            .f1 {
                background: rgba(199, 123, 43, 0.18);
                height: 5%;
                margin: 3% 0 0 3%;
                width: 15%;
                animation: f1 8s infinite;
            }

            .f2 {
                background: #332F2C;
                margin: 15% auto 0 auto;
                height: 8%;
                width: 40%;
                animation: f2 3s infinite;
            }

            .f3 {
                background: #C77B2B;
                margin: 4% auto 0 auto;
                height: 12%;
                width: 82%;
            }

            .f4 {
                background: #66462D;
                margin: 4% auto 0 auto;
                height: 18%;
                width: 57%;
            }

            .f5 {
                background: #B8977E;
                margin: auto;
                height: 4.3%;
                width: 57%;
            }

            .f6 {
                background: rgba(137, 102, 74, 0.3);
                margin: auto;
                height: 12%;  
                width: 57%;
                animation: f6 5s infinite;
            }

            @keyframes f1 {
                0% {
                    width: 15%;
                } 

                50% {
                    width: 80%;
                } 
                
                100% {
                    width: 15%;
                } 
            }

            @keyframes f2 {
                0% {
                    width: 15%;
                    transform: translateX(0%);
                } 

                50% {
                    width: 80%;
                    transform: translate(100%);
                } 
                
                100% {
                    width: 15%;
                } 
            }

            @keyframes f6 {
                0% {
                    margin-top: 0;
                } 

                50% {
                    margin-top: 50px;
                } 
                
                100% {
                    margin-top: 0;
                } 
            }
        }

        .container-vitamin_sea {
            cursor: pointer;
        }
    }
}